import Helpers from "@/helpers/Helpers";

export default {
  path: "/signin",
  components: { default: Helpers.lazyLoad("LoginView", "views/login") },
  children: [
    {
      path: "",
      name: "LoginForm",
      components: {
        default: Helpers.lazyLoad("LoginFormView", "views/login")
      }
    },
    {
      path: "forgot-password",
      name: "ForgotPassword",
      components: {
        default: Helpers.lazyLoad("ForgotPasswordFormView", "views/login")
      }
    },
    {
      path: "password-reset",
      name: "PasswordReset",
      components: {
        default: Helpers.lazyLoad("PasswordResetFormView", "views/login")
      }
    }
  ]
};
