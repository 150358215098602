<template>
  <div class="close-button">
    <v-btn color="white" text small>Dismiss</v-btn>
  </div>
</template>

<script>
export default {
  props: {
    duration: Number
  }
};
</script>

<style lang="scss" scoped>
.close-button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 8px 16px;
  button {
    margin-right: -8px;
  }
}
</style>
