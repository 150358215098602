const POI_PROPERTIES = [
  { title: "Description", key: "description", requestKey: "description", valueType: "text", inputType: "textarea" },
  { title: "External Identifier", key: "eid", requestKey: "eid", valueType: "text", inputType: "textfield" },
  { title: "Opening Hours", key: "openHours", requestKey: "openHours", valueType: "text", inputType: "combobox" },
  { title: "Tags", key: "tags", requestKey: "tags", valueType: "text", inputType: "combobox" },
  { title: "Keywords", key: "keywords", requestKey: "keywords", valueType: "text", inputType: "combobox" },
  { title: "Price", key: "price", requestKey: "price", valueType: "text" },
  {
    title: "Logo Image (Upload)",
    key: "logo_upload",
    requestKey: "logo",
    valueType: "image",
    inputType: "dropfile",
    restriction: "* Max 1MB | JPG & PNG | 1:1",
    maxFileSizeMB: 1,
    aspectRatio: "1:1"
  },
  {
    title: "Logo Image (Direct URL)",
    key: "logo",
    requestKey: "logo",
    valueType: "image",
    inputType: "textfield",
    restriction: "* Max 1MB | JPG & PNG | 1:1"
  },
  {
    title: "Image(s) (Upload)",
    key: "images_upload",
    requestKey: "images",
    valueType: "image",
    inputType: "dropfile",
    maxCount: 7,
    restriction: "* Max 2MB | JPG & PNG | 16:9",
    maxFileSize: 2,
    aspectRatio: "16:9"
  },
  {
    title: "Image(s) (Direct URL)",
    key: "images",
    requestKey: "images",
    valueType: "image",
    inputType: "textfield",
    maxCount: 7,
    restriction: "* Max 2MB | JPG & PNG | 16:9"
  },
  {
    title: "Email",
    key: "email",
    requestKey: "email",
    valueType: "hyperlink",
    name: "E-Mail",
    action: "mailto",
    iconUrl: "https://pointrapps.blob.core.windows.net/core-web-sdk/poi-details-button-icons/email.svg"
  },
  {
    title: "Phone Number",
    key: "phoneNumber",
    requestKey: "phoneNumber",
    valueType: "hyperlink",
    action: "tel",
    name: "Call",
    iconUrl: "https://pointrapps.blob.core.windows.net/core-web-sdk/poi-details-button-icons/telephone.svg"
  },
  {
    title: "Menu",
    key: "menu",
    requestKey: "menu",
    valueType: "hyperlink",
    action: "href",
    name: "Menu",
    iconUrl: "https://pointrapps.blob.core.windows.net/core-web-sdk/poi-details-button-icons/book.svg"
  },
  {
    title: "Website",
    key: "website",
    requestKey: "website",
    valueType: "hyperlink",
    name: "Website",
    action: "href",
    iconUrl: "https://pointrapps.blob.core.windows.net/core-web-sdk/poi-details-button-icons/website.svg"
  },
  {
    title: "Book/Reserve",
    key: "book",
    requestKey: "book",
    valueType: "hyperlink",
    name: "Book",
    action: "href",
    iconUrl: "https://pointrapps.blob.core.windows.net/core-web-sdk/poi-details-button-icons/bookReserve.svg"
  },
  {
    title: "Order",
    key: "order",
    requestKey: "order",
    valueType: "hyperlink",
    name: "Order",
    action: "href",
    iconUrl: "https://pointrapps.blob.core.windows.net/core-web-sdk/poi-details-button-icons/order.svg"
  },
  { title: "Rating", key: "rating", requestKey: "rating", valueType: "hyperlink" }
  //{ title: "Custom", key: "custom", requestKey: "custom", valueType: "custom" }
];

const SECONDARY_PROPERTIES = ["priceSign", "priceMax", "ratingMax", "numberOfRatings", "buttons"];
export default class PoiProperties {
  static get POI_PROPERTIES() {
    return POI_PROPERTIES;
  }
  static get SECONDARY_PROPERTIES() {
    return SECONDARY_PROPERTIES;
  }
}
