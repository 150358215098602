<template>
  <v-dialog v-model="show" internal-activator width="344" eager>
    <v-card class="pa-6">
      <v-btn class="close-popup-icon" color="white" small @click="close">
        <PtrIcon icon="close"></PtrIcon>
      </v-btn>
      <PtrIcon icon="mark-corners" extension="png" class="mark-corners-icon mt-n16"></PtrIcon>

      <v-card-title color="" class="popup-title justify-center">
        {{ $t(`${translationPath}title`) }}
      </v-card-title>
      <v-card-text class="popup-description text-center px-1">{{ $t(`${translationPath}description`) }}</v-card-text>
      <div class="pro-tip">
        <div class="pro-tip-title mb-1">{{ $t(`${translationPath}pro-tip-title`) }}</div>
        <div class="pro-tip-description">{{ $t(`${translationPath}pro-tip-description`) }}</div>
      </div>
      <v-card-actions class="justify-center d-flex flex-column mt-4">
        <v-btn class="py-3 px-4 confirm-popup-button" color="primary" @click="close">
          {{ $t(`${translationPath}ok`) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";

export default {
  name: "MarkCornersPopup",
  components: { PtrIcon },
  data: () => ({
    translationPath: "contents.markCornersPopup.",
    show: true
  }),
  methods: {
    close() {
      this.$emit("close");
      localStorage.setItem("isMarkCornersPopupShown", true);
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-title {
  font-size: 24px !important;
  line-height: 38px;
  color: var(--v-neutral-darken5);
  letter-spacing: 0 !important;
}

.popup-description {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0 !important;
  color: var(--v-neutral-darken5) !important;
}

.pro-tip {
  background-color: var(--v-neutral-darken4);
  box-shadow: 0px 4px 8px rgba(23, 25, 28, 0.16);
  border-radius: 8px;
  padding: 8px;
  width: 208px;
  margin: auto;
  color: white;
  .pro-tip-title {
    font-size: 13px;
    line-height: 18px;
  }
  .pro-tip-description {
    font-size: 11px;
    line-height: 14px;
  }
}

.confirm-popup-button {
  min-width: 55px !important;
}

::v-deep .v-dialog {
  overflow-y: visible;
}

.close-popup-icon {
  position: absolute;
  right: 18px;
  top: 18px;
}
</style>
