import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/store.js";

import AuthHelpers from "@/helpers/AuthHelpers";

import LoginRoutes from "@/router/loginRoutes";
import MainRoutes from "@/router/mainRoutes";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/cms/content"
  },
  {
    path: "/cms",
    redirect: "/cms/content"
  },
  {
    path: "/404",
    redirect: "/cms/content"
  },
  {
    path: "*",
    redirect: "/404"
  }
];
routes.push(LoginRoutes);
routes.push(MainRoutes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  store.commit("MAP/HIDE_UI_ICONS");

  if (to.meta.requiresAuth) {
    await AuthHelpers.refreshToken();
  }

  const isAuthTokenValid = AuthHelpers.isAuthTokenValid();
  if (to.meta.requiresAuth && !isAuthTokenValid) {
    store.commit("CONTENT/IS_FORM_DIRTY", false);
    localStorage.setItem("redirectRoute", JSON.stringify(to));
    AuthHelpers.logout();
    next({ path: "/signin" });
  } else if (to.path === "/signin" && isAuthTokenValid) {
    next(from.path);
  } else {
    next();
  }
});

export default router;
