<template>
  <div class="loader">
    <div class="loaderBar"></div>
  </div>
</template>
<script>
export default {
  name: "LoadingBar"
};
</script>
<style lang="scss" scoped>
.loader {
  position: absolute;
  top: 0;
  width: 100%;
  margin: 0 auto;
  padding: 1px;
  height: 4px;
  z-index: 999;
  box-sizing: border-box;
}
.loader .loaderBar {
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: var(--v-primary-base);
  width: 0;
  animation: borealisBar 2s linear infinite;
}
@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}
</style>
