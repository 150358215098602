import LoginService from "@/services/LoginService";
import ServiceConstants from "@/constants/serviceConstants";

export default class AuthHelpers {
  static EXPIRATION_THRESHOLD_IN_SECONDS = 60;

  static ACCESS_TOKEN_KEY = "access_token";
  static REFRESH_TOKEN_KEY = "refresh_token";

  static login(accessToken, refreshToken) {
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
  }

  static logout() {
    this.removeAccessToken();
    this.removeRefreshToken();
  }

  static async refreshToken() {
    const accessToken = this.getAccessToken();
    const refreshToken = this.getRefreshToken();
    if (!accessToken || !refreshToken) {
      return;
    }
    const parsedToken = this.parseJwt(accessToken);
    if (!parsedToken) {
      return;
    }
    const currentDateInSeconds = Math.floor(Date.now() / 1000);
    const timeToExpire = parsedToken?.exp - currentDateInSeconds;
    if (timeToExpire > this.EXPIRATION_THRESHOLD_IN_SECONDS) {
      return;
    }
    const input = {
      grant_type: ServiceConstants.REFRESH_TOKEN_GRANT_TYPE,
      refresh_token: refreshToken
    };
    try {
      const response = await LoginService.getToken(input);
      const authData = response?.result;
      if (authData?.access_token && authData?.refresh_token) {
        this.login(authData?.access_token, authData?.refresh_token);
      }
    } catch (error) {
      console.log("Refresh token expired");
    }
  }

  static isAuthTokenValid() {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return false;
    }

    try {
      const parsedToken = this.parseJwt(accessToken);
      const currentDateInSeconds = Math.floor(Date.now() / 1000);
      return parsedToken?.exp > currentDateInSeconds;
    } catch (e) {
      return false;
    }
  }

  static parseJwt(token) {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  // getters setters removers
  static getAccessToken() {
    return localStorage.getItem(this.ACCESS_TOKEN_KEY);
  }

  static getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN_KEY);
  }

  static setAccessToken(token) {
    localStorage.setItem(this.ACCESS_TOKEN_KEY, token);
  }

  static setRefreshToken(token) {
    localStorage.setItem(this.REFRESH_TOKEN_KEY, token);
  }

  static removeAccessToken() {
    localStorage.removeItem(this.ACCESS_TOKEN_KEY);
  }

  static removeRefreshToken() {
    localStorage.removeItem(this.REFRESH_TOKEN_KEY);
  }
}
