<template>
  <v-container class="left-menu-expander" :class="{ visible: isMenuShrunk }">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <v-btn
          class="v-btn--square expander-icon"
          small
          icon
          depressed
          plain
          v-bind="attrs"
          v-on="on"
          @click="toggleMenu"
        >
          <PtrIcon icon="hamburger-menu" />
        </v-btn>
      </template>
      <span>{{ $t("button-tooltips.expand-menu") }}</span>
    </v-tooltip>
    <div class="expander-content">
      {{ content }}
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import PtrIcon from "@/components/shared/PtrIcon";

export default {
  name: "PtrMenuExpander",
  components: { PtrIcon },
  props: {
    isMenuShrunk: Boolean
  },
  data: () => ({
    isActive: false,
    translationPath: "contents.titles."
  }),
  computed: {
    content() {
      let contentType;
      switch (this.$route.name) {
        case "ContentPicker":
          return this.currentLevelObject?.levelLongTitle || "";
        case "Levels":
          return this.currentBuildingObject?.buildingTitle || "";
        case "PoiList":
          contentType = this.$t(`${this.translationPath}pois`);
          return `${this.currentLevelObject?.levelLongTitle || ""} / ${contentType}`;
        case "GeofenceList":
          contentType = this.$t(`${this.translationPath}geofences`);
          return `${this.currentLevelObject?.levelLongTitle || ""} / ${contentType}`;
        case "BeaconList":
          contentType = this.$t(`${this.translationPath}beacon-list`);
          return `${this.currentLevelObject?.levelLongTitle || ""} / ${contentType}`;
        case "MapObjectList":
          contentType = this.$t(`${this.translationPath}map-object-list`);
          return `${this.currentLevelObject?.levelLongTitle || ""} / ${contentType}`;
        case "WayfindingNetwork":
          contentType = this.$t(`${this.translationPath}wayfindingnetwork`);
          return `${this.currentLevelObject?.levelLongTitle || ""} / ${contentType}`;
        default:
          return this.$t(`${this.translationPath}${this.$route.name.toLowerCase()}`);
      }
    },
    ...mapGetters("MAP", ["currentBuildingObject", "currentLevelObject"])
  },
  methods: {
    toggleMenu() {
      this.$emit("toggleMenu");
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/variables.scss";
.left-menu-expander {
  height: 100%;
  width: 0;
  position: absolute;
  left: -#{$menu-expander-width};
  overflow: hidden;
  background-color: transparent;
  opacity: 0;
  padding: 0 !important;
  transition: left 0.5s linear;
  &.visible {
    left: 0;
    width: #{$menu-expander-width};
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
  }
  .expander-icon {
    width: #{$menu-expander-width} !important;
    height: #{$menu-expander-width} !important;
    margin-bottom: 60px;
    img {
      width: 20px;
    }
  }
  .expander-content {
    flex-grow: 1;
    text-align: end;
    writing-mode: tb;
    transform: rotate(180deg);
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
