const EMPTY_GEO_JSON = {
  type: "FeatureCollection",
  features: []
};
const SELECTED_SOURCE = "selected_source_ptr";
const SOURCE = "source_ptr";
const BUILDING_OUTLINE_SOURCE = "building-outline_source_ptr";
const SITE_OUTLINE_SOURCE = "site-outline_source_ptr";
const SITE_BORDERS_SOURCE = "site-borders";
const BUILDING_BORDERS_SOURCE = "building-borders";

export default class MapConstants {
  static get EMPTY_GEO_JSON() {
    return EMPTY_GEO_JSON;
  }

  static get SELECTED_SOURCE() {
    return SELECTED_SOURCE;
  }

  static get SOURCE() {
    return SOURCE;
  }

  static get BUILDING_OUTLINE_SOURCE() {
    return BUILDING_OUTLINE_SOURCE;
  }

  static get SITE_OUTLINE_SOURCE() {
    return SITE_OUTLINE_SOURCE;
  }

  static get SITE_BORDERS_SOURCE() {
    return SITE_BORDERS_SOURCE;
  }

  static get BUILDING_BORDERS_SOURCE() {
    return BUILDING_BORDERS_SOURCE;
  }
}
