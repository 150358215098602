<template>
  <div v-if="shouldShowPublishModal || shouldShowCancelModal" class="modal-overlay" @click="goBack">
    <div class="publish-modal modal-content" @click.stop>
      <v-card-title class="publish-modal-title">
        {{ shouldShowPublishModal ? $t(`${translationPath}title`) : $t(`${translationPath}cancelTitle`) }}
      </v-card-title>
      <v-card-subtitle>
        {{ shouldShowPublishModal ? $t(`${translationPath}subtitle`) : $t(`${translationPath}cancelSubtitle`) }}
      </v-card-subtitle>

      <v-row dense>
        <v-col>
          <v-btn
            id="publish-modal-primary-action-btn"
            :color="shouldShowCancelModal ? 'error' : 'primary'"
            @click="shouldShowPublishModal ? publish() : cancel()"
          >
            {{ $t(`${translationPath}yes`) }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-btn id="publish-modal-go-back-action-btn" class="publish-modal-dismiss" color="white" @click="goBack">
            {{ $t(`${translationPath}no`) }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import PreviewService from "@/services/PreviewService";
import Helpers from "@/helpers/Helpers";

export default {
  name: "PublishModal",

  data: () => ({
    translationPath: "contents.publishModal."
  }),

  computed: {
    ...mapState("PREVIEW_MAP", ["shouldShowPublishModal", "shouldShowCancelModal"]),
    ...mapState("MAP", ["currentClient"])
  },

  methods: {
    goBack() {
      this.$store.commit("PREVIEW_MAP/SHOULD_SHOW_PUBLISH_MODAL", false);
      this.$store.commit("PREVIEW_MAP/SHOULD_SHOW_CANCEL_MODAL", false);
    },
    async publish() {
      await PreviewService.approvePreviewMap(this.currentClient);
      this.$store.commit("PREVIEW_MAP/SHOULD_SHOW_PUBLISH_MODAL", false);
      Helpers.routerSafeGoBack("Clients");
    },
    async cancel() {
      await PreviewService.declinePreviewMap(this.currentClient);
      this.$store.commit("PREVIEW_MAP/SHOULD_SHOW_CANCEL_MODAL", false);
      Helpers.routerSafeGoBack("Clients");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  // Left menu width is 64px so we put overlay left to cover
  left: -64px;

  .modal-content {
    width: 330px;

    .building {
      margin: -30% 0 0 10%;
    }

    .publish-modal-title {
      justify-content: center;
    }

    .publish-modal-dismiss {
      color: var(--v-neutral-lighten2);
    }
  }
}
</style>
