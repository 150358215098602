<template>
  <div class="container">
    <span>{{ message }}</span>
    <v-progress-circular :rotate="-90" :size="20" :width="10" :value="countDown" color="primary" />
  </div>
</template>

<script>
export default {
  props: {
    message: String
  },
  data: () => ({
    countDown: 0
  }),
  created() {
    this.countDownTimer();
  },
  methods: {
    countDownTimer() {
      if (this.countDown < 100) {
        setTimeout(() => {
          // countDown is percentage value. So every 100 ms we're updating its valu to 5 sec / 100 ms
          // total duration is 7 sec but due to animations on toast we're completing pie animation in 5 sec.
          this.countDown += 100 / (5000 / 100);
          this.countDownTimer();
        }, 100);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0;
  ::v-deep {
    .v-progress-circular {
      min-width: 20px;
    }
  }
}
</style>
