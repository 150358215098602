<template>
  <div v-if="descendingLevels.length" id="map-ui-levels" :class="{ 'is-expanded': isLevelSelectorExpanded }">
    <v-btn
      v-for="(level, i) in descendingLevels"
      :key="i"
      color="white"
      class="v-btn--square map-ui-level"
      :class="{ 'current-level': level.levelIndex == currentLevel }"
      @click="levelSelected(level.levelIndex)"
    >
      <PtrIcon v-if="!isLevelSelectorExpanded && level.levelIndex !== descendingLevels[0].levelIndex" icon="caret-up" />
      {{ level.levelIndex }}
      <PtrIcon
        v-if="!isLevelSelectorExpanded && level.levelIndex !== descendingLevels[descendingLevels.length - 1].levelIndex"
        icon="caret-down"
      />
    </v-btn>
  </div>
</template>

<script>
import { mapState } from "vuex";
import PtrIcon from "@/components/shared/PtrIcon.vue";

export default {
  components: {
    PtrIcon
  },

  computed: {
    ...mapState("CONTENT", ["sites"]),
    ...mapState("PREVIEW_MAP", ["currentSid", "currentBid", "currentLevel", "isLevelSelectorExpanded"]),
    selectedSite() {
      return this.sites.find((site) => site.siteInternalIdentifier === this.currentSid) || this.sites[0];
    },
    selectedBuilding() {
      return (
        this.selectedSite?.buildings?.find((building) => building.buildingInternalIdentifier === this.currentBid) ||
        this.selectedSite?.buildings?.[0]
      );
    },
    descendingLevels() {
      return [...(this.selectedBuilding?.levels || [])].sort((a, b) => b.levelIndex - a.levelIndex);
    }
  },

  watch: {
    sites() {
      const lastLevelIndex = this.descendingLevels?.[this.descendingLevels?.length - 1]?.levelIndex;
      if (typeof lastLevelIndex === "number") {
        this.$store.commit("PREVIEW_MAP/CURRENT_LEVEL", lastLevelIndex);
      }
    }
  },

  methods: {
    levelSelected(level) {
      this.$store.dispatch("PREVIEW_MAP/SET_CURRENT_LEVEL", level);
    }
  }
};
</script>

<style lang="scss" scoped>
#map-ui-levels {
  display: flex;
  flex-direction: column;
  max-height: 64px;
  position: relative;
  transition: all 0.5s ease-in-out;
  overflow: hidden overlay;
  pointer-events: all;
  padding: 8px;
  margin-bottom: -8px;
  margin-left: -8px;
  &:not(.is-expanded) .map-ui-level {
    margin-top: 0 !important;
  }
  .map-ui-level:not(.current-level) {
    transition: all 0.5s ease-in-out;
    display: none;
  }

  &.is-expanded {
    max-height: 100vh;
    .map-ui-level {
      display: flex !important;
    }

    .current-level {
      background: var(--v-primary-lighten4) !important;
      color: var(--v-primary-base) !important;
    }
  }

  .caret-up,
  .caret-down {
    display: none;
    position: absolute;
    padding: 0 !important;
  }

  .caret-up {
    top: -10px;
  }

  .caret-down {
    bottom: -10px;
  }
  .map-ui-level.current-level {
    position: sticky;
    top: 0;
    left: 0;

    .caret-up,
    .caret-down {
      display: flex;
    }
  }
}
</style>
