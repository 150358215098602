import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/store";
import i18n from "./plugins/i18n";
import vuetify from "./plugins/vuetify";
import toastification from "./plugins/toastification";
import virtualScroller from "./plugins/virtualScroller";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

Vue.config.productionTip = false;
Vue.use(VueTelInput);

const vm = new Vue({
  router,
  store,
  i18n,
  vuetify,
  toastification,
  virtualScroller,
  render: (h) => h(App)
}).$mount("#app");

if (window.Cypress) {
  window.VueApp = vm;
}

export default vm;
