const MAP_EVENTS = {
  LOAD: "load",
  CLICK: "click",
  DRAW_CREATE: "draw.create",
  DRAW_UPDATE: "draw.update",
  DRAW_MODE_CHANGE: "draw.modechange",
  DRAW_DELETE: "draw.delete",
  ZOOM: "zoom",
  ZOOM_START: "zoomstart",
  ZOOM_END: "zoomend",
  DRAG_END: "dragend"
};

export default class MapEventConstants {
  static get LOAD() {
    return MAP_EVENTS.LOAD;
  }

  static get CLICK() {
    return MAP_EVENTS.CLICK;
  }

  static get DRAW_CREATE() {
    return MAP_EVENTS.DRAW_CREATE;
  }

  static get DRAW_UPDATE() {
    return MAP_EVENTS.DRAW_UPDATE;
  }

  static get DRAW_MODE_CHANGE() {
    return MAP_EVENTS.DRAW_MODE_CHANGE;
  }

  static get DRAW_DELETE() {
    return MAP_EVENTS.DRAW_DELETE;
  }

  static get ZOOM() {
    return MAP_EVENTS.ZOOM;
  }

  static get ZOOM_START() {
    return MAP_EVENTS.ZOOM_START;
  }

  static get ZOOM_END() {
    return MAP_EVENTS.ZOOM_END;
  }

  static get DRAG_END() {
    return MAP_EVENTS.DRAG_END;
  }
}
