<template>
  <div>
    <v-list-item v-if="!isEdit" :ripple="false">
      <v-list-item-content @click.stop="editProperty">
        <v-list-item-title class="custom-integraton-row-title">{{ datumsKey }}</v-list-item-title>
        <v-list-item-subtitle class="custom-integraton-row-subtitle">{{ datumsValue }} </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action style="flex-direction: row">
        <v-btn class="v-btn--square edit-btn" small icon depressed plain @click.stop="editProperty">
          <PtrIcon icon="edit" />
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <CustomIntegrationForm
      v-else
      :is-edit="true"
      :extra-data="{ key: datumsKey, value: datumsValue }"
      @cancel="cancelEdit"
      @add="updateEdit"
      @delete="deleteDatum"
      @setFormDirty="setFormDirty"
    ></CustomIntegrationForm>
  </div>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";
import CustomIntegrationForm from "@/components/shared/CustomIntegrationForm.vue";
export default {
  components: { PtrIcon, CustomIntegrationForm },
  props: {
    propertyKey: String,
    propertyValue: String,
    disable: Boolean
  },
  data: () => ({
    isEdit: false,
    datumsKey: "",
    datumsValue: ""
  }),
  watch: {
    propertyKey() {
      this.datumsKey = this.propertyKey;
    },
    propertyValue() {
      this.datumsValue = this.propertyValue;
    }
  },
  created() {
    this.datumsKey = this.propertyKey;
    this.datumsValue = this.propertyValue;
  },
  methods: {
    editProperty() {
      if (this.disable || this.isEdit) {
        return;
      }
      this.isEdit = true;
      this.$emit("edit");
    },
    cancelEdit() {
      this.isEdit = false;
      this.$emit("cancel");
    },
    updateEdit(newDatum) {
      this.datumsKey = newDatum.key;
      this.datumsValue = newDatum.value;
      this.$emit("update", { key: this.datumsKey, value: this.datumsValue });
      this.isEdit = false;
    },
    deleteDatum() {
      this.$emit("delete");
      this.isEdit = false;
    },
    setFormDirty() {
      this.$emit("setFormDirty");
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list-item:hover {
  cursor: pointer;
  .v-list-item__title {
    color: var(--v-primary-base) !important;
  }
  .v-list-item__subtitle {
    color: var(--v-primary-lighten1) !important;
  }
}
.v-list-item {
  padding-left: 0px;
}
.custom-integraton-row-title {
  font-size: 13px;
}
.custom-integraton-row-subtitle {
  font-size: 11px;
}
</style>
