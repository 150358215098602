export const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");

export function isDarkTheme(theme) {
  return theme === "system" ? prefersDarkScheme.matches : theme === "force-dark";
}

export function setupSystemThemeListener(vuetify) {
  prefersDarkScheme.addListener((mediaQuery) => {
    if (localStorage.getItem("theme") === "system") {
      vuetify.framework.theme.dark = mediaQuery.matches;
    }
  });
}
