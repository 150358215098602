<template>
  <v-snackbar
    v-model="snackbar"
    class="guidance-popup"
    content-class="guidance-content"
    timeout="-1"
    color="primary"
    absolute
  >
    <div class="d-flex align-center justify-start">
      <PtrIcon icon="guidance" class="mr-3" width="20" />{{ guidanceMessage }}
    </div>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
import PtrIcon from "@/components/shared/PtrIcon.vue";

export default {
  name: "GuidancePopup",
  components: { PtrIcon },
  data: () => ({
    snackbar: true
  }),
  computed: {
    ...mapState("MAP", ["guidanceMessage"])
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.guidance-popup {
  bottom: 60px;
  left: calc(#{$content-add-edit-panel-width} / -2);
}
::v-deep .guidance-content {
  font-weight: 700;
  font-size: 13px !important;
}

::v-deep {
  .v-snack__wrapper {
    min-width: auto !important;
  }
}
</style>
