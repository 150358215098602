import Helpers from "@/helpers/Helpers";
// import ConfigurationRoutes from "./configRoutes";
import ContentRoutes from "./contentRoutes";

import ContentView from "@/views/ContentView.vue";

export default {
  path: "/cms",
  component: Helpers.lazyLoad("CmsView"),
  children: [
    {
      path: "activity",
      name: "Activity",
      component: Helpers.lazyLoad("PtrSidebar"),
      meta: { requiresAuth: true }
    },
    {
      path: "content",
      name: "Content",
      redirect: "/cms/content/clients",
      components: {
        default: ContentView,
        PublishModal: Helpers.lazyLoad("PublishModal", "components/preview"),
        InformationModals: Helpers.lazyLoad("InformationModals", "components/shared"),
        VersionInformationModal: Helpers.lazyLoad("VersionInformationModal", "components/shared")
      },
      children: ContentRoutes,
      meta: { requiresAuth: true }
    },
    {
      path: "analysis",
      name: "Analysis",
      component: Helpers.lazyLoad("PtrSidebar"),
      meta: { requiresAuth: true }
    },
    {
      path: "reports",
      name: "Reports",
      component: Helpers.lazyLoad("PtrSidebar"),
      meta: { requiresAuth: true }
    }
    //TODO: Uncomment when config is ready
    // {
    //   path: "config",
    //   name: "Config",
    //   redirect: "/cms/config/system-preferences",
    //   component: Helpers.lazyLoad("ConfigurationView"),
    //   children: ConfigurationRoutes,
    //   meta: { requiresAuth: true }
    // }
  ]
};
