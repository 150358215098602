const MAP_MODES = {
  DRAW_POLYGON: "draw_polygon",
  DRAW_POINT: "draw_point",
  DRAW_CIRCLE: "draw_circle",
  DRAG_CIRCLE: "drag_circle",
  DRAW_GRAPH_MODE: "graph_mode",
  SIMPLE_SELECT: "simple_select",
  DIRECT_SELECT: "direct_select",
  CUT_POLYGON: "cut_polygon",
  DISABLE: "disable",
  DRAW_RECTANGLE: "draw_rectangle",
  PASSING_MODE_POLYGON: "passing_mode_polygon",
  EMPTY_MODE: ""
};
Object.freeze(MAP_MODES);

export default class MapModeConstants {
  static get MAP_MODES() {
    return MAP_MODES;
  }

  static get DRAW_POLYGON() {
    return MAP_MODES.DRAW_POLYGON;
  }

  static get DRAW_RECTANGLE() {
    return MAP_MODES.DRAW_RECTANGLE;
  }

  static get DRAW_POINT() {
    return MAP_MODES.DRAW_POINT;
  }

  static get DRAW_CIRCLE() {
    return MAP_MODES.DRAW_CIRCLE;
  }

  static get DRAG_CIRCLE() {
    return MAP_MODES.DRAG_CIRCLE;
  }

  static get DRAW_GRAPH_MODE() {
    return MAP_MODES.DRAW_GRAPH_MODE;
  }

  static get SIMPLE_SELECT() {
    return MAP_MODES.SIMPLE_SELECT;
  }

  static get DIRECT_SELECT() {
    return MAP_MODES.DIRECT_SELECT;
  }

  static get CUT_POLYGON() {
    return MAP_MODES.CUT_POLYGON;
  }

  static get PASSING_MODE_POLYGON() {
    return MAP_MODES.PASSING_MODE_POLYGON;
  }

  static get EMPTY_MODE() {
    return MAP_MODES.EMPTY_MODE;
  }

  static get DISABLE() {
    return MAP_MODES.DISABLE;
  }
}
