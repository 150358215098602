<template>
  <div class="beta-tag" :class="cssClass">{{ text }}</div>
</template>

<script>
export default {
  name: "BetaTag",
  props: {
    status: {
      type: String,
      default: "beta"
    }
  },
  data: () => ({
    translationPath: "tags."
  }),
  computed: {
    text() {
      return this.$t(`${this.translationPath}${this.status}`);
    },
    cssClass() {
      return this.status;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
.beta-tag {
  border-radius: 8px;
  display: inline-flex;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 11px;
  &.beta {
    border: 1px solid #c28005;
    color: white;
    background: #f4a106;
  }
  &.experimental {
    border-radius: var(--spacing-s, 8px);
    border: 1px solid #1f6d93;
    background: #288cbd;
    color: white;
  }
}
</style>
