const source = (data) => {
  return {
    type: "geojson",
    data: data
  };
};

const dashedLineLayer = {
  id: "dashed-line",
  type: "line",
  source: "dashed-line-source",
  layout: {
    "line-join": "round",
    "line-cap": "round"
  },
  paint: {
    "line-color": "orange",
    "line-opacity": 0.75,
    "line-width": 2,
    "line-dasharray": [1, 2]
  }
};

const graphLayer = {
  id: "graph",
  type: "line",
  source: "graph-source",
  layout: {
    "line-join": "round",
    "line-cap": "round"
  },
  paint: {
    "line-color": "#346df1",
    "line-width": 4
  }
};

const snappedNodeLayer = {
  id: "snap",
  type: "circle",
  source: "snap-circle-source",
  paint: {
    "circle-color": "#FFFFFF",
    "circle-radius": 12,
    "circle-stroke-width": 6,
    "circle-stroke-color": "#346df1"
  }
};

const customMarker = ({ markerId = new Date().getTime(), className = "custom-marker" }) => {
  let marker = document.createElement("div");
  marker.className = `${className} ${className}-${markerId}`;
  marker.markerId = markerId;
  return marker;
};

const customTransitionMarker = ({
  markerId = new Date().getTime(),
  className = "custom-marker",
  typeCode = "custom-transition"
}) => {
  let marker = document.createElement("img");
  try {
    marker.src = require(`@/assets/custom-icons/${typeCode}.svg`);
  } catch (e) {
    marker.src = require(`@/assets/custom-icons/custom-transition.svg`);
  }

  marker.className = ` ${className}-${markerId} transition-node ${typeCode}-${markerId}`;
  marker.markerId = markerId;
  return marker;
};

const createPopupButton = ({ btnText, clickFunc, btnColor }) => {
  let popupBtn = document.createElement("div");
  popupBtn.className = "popup-btn";
  popupBtn.innerText = btnText;
  popupBtn.style.color = btnColor || "black";
  popupBtn.addEventListener("click", () => clickFunc());
  return popupBtn;
};

const createPopupElem = (btnArr) => {
  let popupElem = document.createElement("div");
  popupElem.className = "node-popup";
  return btnArr.reduce((finalPopup, btn) => {
    finalPopup.appendChild(btn);
    return finalPopup;
  }, popupElem);
};

const setIpadHeight = () => {
  document.documentElement.style.setProperty("--ipad-height", `${window.innerHeight}px`);
};

module.exports = {
  source,
  dashedLineLayer,
  graphLayer,
  snappedNodeLayer,
  customMarker,
  customTransitionMarker,
  createPopupButton,
  createPopupElem,
  setIpadHeight
};
