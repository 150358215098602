import Vue from "vue";
import Toast from "vue-toastification";
import "@/scss/custom-vue-toastification.scss";
import CustomCloseButton from "@/components/shared/CustomCloseButton.vue";

const options = {
  position: "bottom-right",
  timeout: 6000,
  closeOnClick: true,
  draggable: false,
  draggablePercent: 0.6,
  hideProgressBar: true,
  closeButton: CustomCloseButton,
  icon: false,
  pauseOnHover: false,
  transition: "Vue-Toastification__fade"
};

Vue.use(Toast, options);

export default new Vue({});
