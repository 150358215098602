import Helpers from "@/helpers/Helpers";

export default [
  //   BUILDINGS
  {
    path: "clients/:clientId/sites/:siteId/buildings/:buildingId/edit",
    name: "BuildingEdit",
    component: Helpers.lazyLoad("BuildingAddEditView", "views/content/buildings"),
    children: [
      {
        path: "advanced-configurations",
        name: "EditBuildingAdvancedConfigurations",
        component: Helpers.lazyLoad("AdvancedSdkConfigs", "views/content"),
        meta: { requiresAuth: true }
      }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/:siteId/buildings/new",
    name: "BuildingAdd",
    component: Helpers.lazyLoad("BuildingAddEditView", "views/content/buildings"),
    children: [
      {
        path: "advanced-configurations",
        name: "AddBuildingAdvancedConfigurations",
        component: Helpers.lazyLoad("AdvancedSdkConfigs", "views/content"),
        meta: { requiresAuth: true }
      }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/:siteId/buildings/:buildingId?",
    name: "Buildings",
    component: Helpers.lazyLoad("BuildingsView", "views/content/buildings"),
    meta: { requiresAuth: true }
  }
];
