<template>
  <div :key="featureId">
    <div v-if="Object.keys(extraData).length === 0">
      <EmptyList v-if="!isAddNewActive" :message="$t(`${translationPath}no-integrations`)" @addNew="addNewClicked" />
      <AddNewPropertyRow v-if="showAddNew" @addNewClicked="addNewClicked"></AddNewPropertyRow>
      <CustomIntegrationForm
        v-if="isAddNewActive"
        :is-edit="false"
        @cancel="canceled"
        @add="added"
        @setFormDirty="setFormDirty"
      ></CustomIntegrationForm>
    </div>

    <div v-else>
      <AddNewPropertyRow v-if="showAddNew" @addNewClicked="addNewClicked"></AddNewPropertyRow>
      <CustomIntegrationForm
        v-if="isAddNewActive"
        :is-edit="false"
        @cancel="canceled"
        @add="added"
        @setFormDirty="setFormDirty"
      ></CustomIntegrationForm>

      <CustomIntegrationRow
        v-for="(propertyKey, index) in Object.keys(extraData)"
        :key="index"
        :property-key="propertyKey"
        :property-value="extraData[propertyKey]"
        :disable="isAddNewActive || !showAddNew"
        @cancel="canceled"
        @edit="showAddNew = false"
        @update="(datum) => updated(datum, propertyKey)"
        @delete="deleted(propertyKey)"
        @setFormDirty="setFormDirty"
      ></CustomIntegrationRow>
    </div>
  </div>
</template>

<script>
import EmptyList from "@/components/shared/EmptyList.vue";
import AddNewPropertyRow from "@/components/shared/AddNewPropertyRow.vue";
import CustomIntegrationForm from "@/components/shared/CustomIntegrationForm.vue";
import CustomIntegrationRow from "@/components/shared/CustomIntegrationRow.vue";

export default {
  components: { EmptyList, AddNewPropertyRow, CustomIntegrationForm, CustomIntegrationRow },
  props: {
    extraDataProp: Object || Array,
    featureId: String
  },
  data: () => ({
    translationPath: "contents.mapDesigner.",
    showAddNew: true,
    propertyName: "",
    propertyValue: "",
    isAddNewActive: false,
    extraData: {}
  }),
  watch: {
    extraDataProp() {
      this.canceled();
      this.extraData = this.extraDataProp || {};
    }
  },
  created() {
    this.extraData = this.extraDataProp || {};
    if (Object.keys(this.extraData)?.length) {
      this.showAddNew = true;
    }
  },
  methods: {
    addNewClicked() {
      this.showAddNew = false;
      this.isAddNewActive = true;
    },
    canceled() {
      this.isAddNewActive = false;
      this.showAddNew = true;
    },
    added(newDatum) {
      this.isAddNewActive = false;
      this.extraData[newDatum.key] = newDatum.value;
      this.showAddNew = true;
      this.$emit("integrationUpdated");
    },
    updated(newDatum, propertyKey) {
      delete this.extraData[propertyKey];
      this.extraData[newDatum.key] = newDatum.value;
      this.isAddNewActive = false;
      this.showAddNew = true;
      this.$emit("integrationUpdated");
    },
    deleted(propertyKey) {
      delete this.extraData[propertyKey];
      this.extraData = {
        ...this.extraData
      };
      this.isAddNewActive = false;
      this.showAddNew = true;
      this.$emit("integrationUpdated");
    },
    getExtraData() {
      return this.extraData;
    },
    setFormDirty() {
      this.$emit("setFormDirty");
    }
  }
};
</script>

<style lang="scss" scoped></style>
