import Helpers from "@/helpers/Helpers";

export default [
  //   SITES
  {
    path: "clients/:clientId/sites/:siteId/edit",
    name: "SiteEdit",
    components: { default: Helpers.lazyLoad("SiteAddEditView", "views/content/sites") },
    children: [
      {
        path: "advanced-configurations",
        name: "EditSiteAdvancedConfigurations",
        component: Helpers.lazyLoad("AdvancedSdkConfigs", "views/content"),
        meta: { requiresAuth: true }
      }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/new",
    name: "SiteAdd",
    components: { default: Helpers.lazyLoad("SiteAddEditView", "views/content/sites") },
    children: [
      {
        path: "advanced-configurations",
        name: "AddSiteAdvancedConfigurations",
        component: Helpers.lazyLoad("AdvancedSdkConfigs", "views/content"),
        meta: { requiresAuth: true }
      }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/:siteId?",
    name: "Sites",
    component: Helpers.lazyLoad("SitesView", "views/content/sites"),
    meta: { requiresAuth: true }
  },
  // GLOBAL GEOFENCES
  {
    path: "clients/:clientId/sites/global-geofences/:globalGeofenceId/edit",
    name: "GlobalGeofenceEdit",
    components: { default: Helpers.lazyLoad("GlobalGeofenceAddEditView", "views/content/sites") },
    children: [
      {
        path: "advanced-configurations",
        name: "EditGlobalGeofenceAdvancedConfigurations",
        component: Helpers.lazyLoad("AdvancedContentConfigs", "views/content/mapDesigner"),
        meta: { requiresAuth: true }
      }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/global-geofences/new",
    name: "GlobalGeofenceAdd",
    components: { default: Helpers.lazyLoad("GlobalGeofenceAddEditView", "views/content/sites") },
    children: [
      {
        path: "advanced-configurations",
        name: "AddGlobalGeofenceAdvancedConfigurations",
        component: Helpers.lazyLoad("AdvancedContentConfigs", "views/content/mapDesigner"),
        meta: { requiresAuth: true }
      }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: "clients/:clientId/sites/global-geofences/:globalGeofenceId?",
    name: "GlobalGeofences",
    component: Helpers.lazyLoad("SitesView", "views/content/sites"),
    meta: { requiresAuth: true }
  }
];
