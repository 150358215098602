<template>
  <div class="empty-list py-2 px-4">
    <v-row>
      <v-col
        class="d-flex align-center justify-start"
        :class="vertical ? 'flex-column gap-xs' : 'empty-list-line py-5'"
      >
        <PtrIcon :icon="iconName" :width="iconWidth" :color="iconColor" :editable-color="!!iconColor" class="my-2" />
        <div v-if="!hasContentSlot">
          <p class="mb-0">{{ message }}</p>
          <p class="mb-0">
            <v-btn class="add-new-button px-0" color="primary darken3" text @click="onAddNewClicked">
              {{ $t(`addNew`) }}
            </v-btn>
            <span>{{ $t("to-get-started") }}</span>
          </p>
        </div>
        <div class="empty-content-header">
          <slot name="header"></slot>
        </div>
        <div class="empty-content-description">
          <slot name="description"></slot>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon";

export default {
  name: "EmptyList",
  components: { PtrIcon },
  props: {
    message: String,
    iconName: {
      type: String,
      default: "alert-icon"
    },
    iconColor: String,
    vertical: {
      type: Boolean,
      default: false
    },
    iconWidth: {
      type: [Number, String]
    }
  },
  computed: {
    hasContentSlot() {
      return !!this.$slots.header || !!this.$slots.description;
    }
  },
  methods: {
    onAddNewClicked() {
      this.$emit("addNew");
    }
  }
};
</script>

<style lang="scss" scoped>
.empty-list {
  padding: 16px;
  background-color: var(--v-primary-lighten5);
  border-radius: 8px;
  color: var(--v-primary-base);
  p,
  .add-new-button {
    font-size: 13px !important;
    line-height: 18px !important;
    font-weight: 700 !important;
  }
}
.empty-list-line {
  gap: 20px;
  .custom-icon {
    margin: 0 !important;
  }
}
.add-new-button {
  min-height: 18px !important;
  margin-top: -3px;

  ::v-deep span {
    text-decoration: underline;
  }
}
.empty-content-header {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.empty-content-description {
  font-size: 11px;
  line-height: 14px;
}

.empty-content-header,
.empty-content-description {
  text-align: center;
}
</style>
