import vm from "../main";
import axios from "./PointrCloudAxios";

import plainAxios from "axios";

export default class PreviewService {
  static async getStatus(clientInternalIdentifier) {
    let response;
    try {
      response = await axios.get(`/clients/${clientInternalIdentifier}/maps/status`);
    } catch (error) {
      console.error(error);
    }
    return response?.data?.result;
  }

  static async startPreviewConversion(clientInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.post(`/clients/${clientInternalIdentifier}/maps/convert`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.result;
  }

  static async getPreviewStyleJson(clientInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      const configResponse = await axios.get(
        `/clients/${clientInternalIdentifier}/configurations/sdk-configurations/draft`
      );
      const styleJsonUrl = configResponse?.data?.result?.globalConfiguration?.sdkConfiguration?.styleJsonUrl;
      response = await plainAxios.get(styleJsonUrl);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data;
  }

  static async approvePreviewMap(clientInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.put(`/clients/${clientInternalIdentifier}/maps/approve`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.result;
  }

  static async declinePreviewMap(clientInternalIdentifier) {
    let response;
    vm.$store.commit("ADD_LOADING_REQUEST");
    try {
      response = await axios.put(`/clients/${clientInternalIdentifier}/maps/decline`);
    } catch (error) {
      console.error(error);
    } finally {
      vm.$store.commit("REMOVE_LOADING_REQUEST");
    }
    return response?.data?.result;
  }
}
