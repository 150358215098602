export default {
  namespaced: true,
  state: () => ({
    map: undefined,
    shouldShowPublishModal: false,
    shouldShowCancelModal: false,
    currentLevel: undefined,
    currentBid: undefined,
    currentSid: undefined,
    isLevelSelectorExpanded: false,
    approvedTilesJsonUrl: "",
    previewTilesJsonUrl: "",
    isMapReady: false,
    zoom: 17
  }),

  mutations: {
    MAP(state, map) {
      state.map = map;
    },
    ZOOM(state, zoom) {
      state.zoom = zoom;
      state.map.jumpTo({
        zoom: zoom
      });
    },
    SHOULD_SHOW_PUBLISH_MODAL(state, shouldShowPublishModal) {
      state.shouldShowPublishModal = shouldShowPublishModal;
    },
    SHOULD_SHOW_CANCEL_MODAL(state, shouldShowCancelModal) {
      state.shouldShowCancelModal = shouldShowCancelModal;
    },
    CURRENT_LEVEL(state, level) {
      state.currentLevel = level;
    },
    CURRENT_BUILDING_INTERNAL_IDENTIFIER(state, buildingInternalIdentifier) {
      state.currentBid = buildingInternalIdentifier;
    },
    CURRENT_SITE_INTERNAL_IDENTIFIER(state, siteInternalIdentifier) {
      state.currentSid = siteInternalIdentifier;
    },
    IS_LEVEL_SELECTOR_EXPANDED(state, isExpanded) {
      state.isLevelSelectorExpanded = isExpanded;
    },
    MAP_READY(state, value) {
      state.isMapReady = value;
    },
    SET_PREVIEW_TILES_JSON(state, value) {
      state.previewTilesJsonUrl = value;
    }
  },
  actions: {
    SET_CURRENT_LEVEL({ state, commit }, level) {
      if (state.isLevelSelectorExpanded) {
        commit("CURRENT_LEVEL", level);
      }
      commit("IS_LEVEL_SELECTOR_EXPANDED", !state.isLevelSelectorExpanded);
    }
  }
};
