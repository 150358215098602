export default {
  namespaced: true,
  state: () => ({
    initialSdkConfigurations: "[]",
    cachedSdkConfigurations: [],
    initialExtraData: "[]",
    cachedExtraData: []
  }),
  mutations: {
    INITIAL_SDK_CONFIGURATIONS(state, configs = "[]") {
      state.initialSdkConfigurations = configs;
    },
    CACHED_SDK_CONFIGURATIONS(state, configs = []) {
      state.cachedSdkConfigurations = configs;
    },
    INITIAL_EXTRA_DATA(state, configs = "[]") {
      state.initialExtraData = configs;
    },
    CACHED_EXTRA_DATA(state, configs = []) {
      state.cachedExtraData = configs;
    }
  },
  actions: {
    SET_CACHED_SDK_CONFIGURATIONS({ state, commit }, payload) {
      commit("CACHED_SDK_CONFIGURATIONS", payload.configs);
      if (JSON.stringify(payload.configs) !== state.initialSdkConfigurations) {
        commit("CONTENT/IS_FORM_DIRTY", true, { root: true });
      }
    },
    SET_CACHED_EXTRA_DATA({ state, commit }, payload) {
      commit("CACHED_EXTRA_DATA", payload.configs);
      if (payload.shouldSetFormDirty && JSON.stringify(payload.configs) !== state.initialExtraData) {
        commit("CONTENT/IS_FORM_DIRTY", true, { root: true });
      }
    },
    CLEAR_ALL_CONFIGURATIONS({ commit }) {
      commit("INITIAL_SDK_CONFIGURATIONS");
      commit("CACHED_SDK_CONFIGURATIONS");
      commit("INITIAL_EXTRA_DATA");
      commit("CACHED_EXTRA_DATA");
    }
  }
};
