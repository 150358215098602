<template>
  <div id="map-settings-container">
    <ShowFloorPlanToggle></ShowFloorPlanToggle>
  </div>
</template>
<script>
import ShowFloorPlanToggle from "@/components/MapSettings/ShowFloorPlanToggle.vue";
export default {
  name: "MapSettings",
  components: { ShowFloorPlanToggle }
};
</script>
<style lang="scss">
#map-settings-container {
  position: absolute;
  bottom: 40px;
  left: 40px;
}
</style>
