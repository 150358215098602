<template>
  <div class="sidebar ipad-safari-height">
    <slot />
  </div>
</template>

<script>
export default {
  name: "PtrSidebar"
};
</script>

<style lang="scss" scoped>
.sidebar {
  height: 100vh;
  box-shadow: 0px 4px 8px rgb(23 25 28 / 16%);

  .row:first-child {
    min-height: 72px !important;
  }

  @media only screen and (max-width: 900px) {
    min-width: auto;
  }
  @media only screen and (max-width: 600px) {
    display: none;
  }
}
</style>
