<template>
  <div>
    <v-text-field v-model="searchInput" solo :label="$t('search')" hide-details @keyup.enter="search">
      <template #append>
        <PtrIcon
          class="mr-2"
          icon="cancel-circle"
          style="cursor: pointer"
          :style="{ visibility: searchInput.length ? 'visible' : 'hidden' }"
          @click.native="
            searchInput = '';
            clearSearch();
          "
        />
        <v-icon>mdi-magnify</v-icon>
      </template>
    </v-text-field>
    <v-card v-if="searchResults && shouldShowResults" class="results">
      <v-list-item-group>
        <template v-if="isLoading">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title style="font-size: 15px; text-align: center"> {{ $t("loading") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-else-if="searchResults.length === 0">
          <div style="font-size: 15px; text-align: center; margin: 12px 0">{{ $t("contents.no-results") }}</div>
        </template>
        <template v-else>
          <template v-for="(result, id) in searchResults">
            <v-list-item :key="result" class="result" @click="handleResultClick(result)">
              <v-list-item-content>
                <v-list-item-title class="list-item-title">{{ result }}</v-list-item-title>
                <v-list-item-subtitle class="list-item-subtitle">{{ searchResultsDetails[id] }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list-item-group>
    </v-card>
  </div>
</template>

<script>
import PtrIcon from "@/components/shared/PtrIcon.vue";

export default {
  name: "PtrSearch",
  components: { PtrIcon },
  props: {
    searchResults: Array,
    searchResultsDetails: Array,
    autoSearchLength: { type: Number, default: 3 },
    isLoading: { type: Boolean, default: false }
  },

  data: () => ({
    searchInput: "",
    isResultSelected: undefined,
    shouldShowResults: false
  }),

  watch: {
    searchInput() {
      this.shouldShowResults = false;
      if (!this.isResultSelected && this.searchInput.length >= this.autoSearchLength) {
        this.search();
      }
      this.isResultSelected = false;
    }
  },

  methods: {
    search() {
      this.$emit("searched", this.searchInput);
      if (!this.isResultSelected) {
        this.shouldShowResults = true;
      }
    },
    clearResults() {
      this.$emit("cleared");
      this.isResultSelected = false;
      this.shouldShowResults = false;
    },
    clearSearch() {
      this.searchInput = "";
      this.clearResults();
    },
    handleResultClick(result) {
      this.clearResults();
      this.$emit("result-selected", result);
      this.searchInput = result;
      this.isResultSelected = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.pointr-search {
  position: relative;

  .results {
    position: absolute !important;
    width: 100%;
    z-index: 1000;
    margin-top: 8px;

    &::before {
      position: absolute;
      content: "";
      left: 50%;
      transform: translate(-50%, -100%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 8px solid #fff;
    }
  }
}

.list-item-title {
  font-size: 15px;
  text-align: center;
}

.list-item-subtitle {
  font-size: 12px;
  text-align: center;
}
</style>
