<template>
  <div class="custom-icon" :class="icon">
    <img
      v-if="!editableColor"
      :width="width"
      :height="height"
      :src="imageUrl"
      :class="{ disabled: disabled }"
      alt="pointr-icon"
    />
    <svg-filler
      v-else
      :width="width"
      :height="height"
      :src="imageUrl"
      :class="{ disabled: disabled }"
      :path="imageUrl"
      :fill="color"
      :stroke="strokeColor"
    />
  </div>
</template>

<script>
import SvgFiller from "vue-svg-filler";

export default {
  name: "PtrIcon",
  components: {
    SvgFiller
  },

  props: {
    editableColor: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    width: {
      type: [Number, String]
    },
    height: {
      type: [Number, String]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "#9095A2"
    },
    strokeColor: {
      type: String,
      default: "none"
    },
    extension: {
      type: String,
      default: "svg"
    },
    rounded: {
      type: Boolean
    }
  },

  computed: {
    imageUrl() {
      return require(`@/assets/custom-icons/${this.icon}.${this.extension}`);
    }
  }
};
</script>

<style lang="scss">
.custom-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  user-select: none;

  .disabled {
    filter: grayscale(1) opacity(0.5);
  }
}

.selected {
  .custom-icon,
  span {
    filter: brightness(0) invert(1);
  }

  &:active {
    .custom-icon,
    span {
      filter: none;
    }
  }
}
</style>
